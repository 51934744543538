import React from "react"
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"
import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

import { useInView } from 'react-intersection-observer'

export interface ImageAndTextProps {
  data: {
    image: {
      imageId: string
      url: string
    }
    subTitle?: string
    text: any[]
  }
}

export const ImageAndText = ({ data }: ImageAndTextProps) => {
  const { image, text, subTitle, alignment } = data

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true
  })

  return (
    <div className="x al p1 mb4 mt4 mt6--800 mb6--600 mt8--1000 mb8--1000" ref={ref}>
      <div className='container--1300 mxa p1 p2--800'>
        <div className={cx('row gutter--none df ba bc fw jcb', alignment)}>
          <div className={cx('col c14 bc c5--800', alignment === 'fdrr' && 'bl1--800')}>
            <div className='df jcb aie fdc y'>
              {subTitle && (
                <div className='x p1 bb bc tc'>
                  <span className='courier s14'>{subTitle}</span>
                </div>
              )}
              <div className={cx('p2 p4--1000 animate-text', inView && 'animated')}>
                <BlockContent blocks={text} serializers={Serializer} />
              </div>
            </div>
          </div>
          <div className={cx('col c14  bc c9--800', alignment !== 'fdrr' && 'bl1--800')}>
            <div className='p2 p4--1000'>
              <Image className='x' imageId={image.imageId} src={image.url} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageAndText